import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-15.css';

const PageFifteen = forwardRef((props, ref) => (
  <>
    <SEO title="Page 15" />
    <main className="page page-reverse page15">
      <div className="page__image-container">
        <StaticImage
          alt="Amanda back at her home and Jimbugs is handing her a gift"
          aspectRatio={3500 / 4913}
          className="page__image page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-15.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            All too soon the tour was over.
          </p>
          <p>
            &ldquo;You all seem so happy,&rdquo; said Amanda, feeling amazed with everything she had seen.
            &ldquo;But now I must go back home before my parents start to worry.&rdquo;
          </p>
          <p>
            Jimbugs led Amanda back. When she took the sock off her head she was in her own garden.
            Amanda and her new friend looked at each other.
          </p>
          <p>
            Jimbugs was the first to speak. &ldquo;I&apos;m really glad I met you. I&apos;m sorry that you
            were so upset every time a sock went missing. Look, here is a pair of goobles.&rdquo;
          </p>
          <p>
            Jimbugs took them from a pocket in his jigglers, and continued,
          </p>
          <p>
            &ldquo;Whenever I take a sock I’ll put a sockpicker surprise in its place.&rdquo;
          </p>
          <p>
            &ldquo;That&apos;s a great idea!&rdquo; replied Amanda, &ldquo;Thank you, these goobles will
            fit my doll.&rdquo;
          </p>
        </div>
      </div>
      <PreviousPage to="/page-14" />
      <NextPage to="/page-16" />
    </main>
  </>
));

const PageFifteenContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageFifteen ref={textRef} />;
};

export default PageFifteenContainer;
